<template>
  <div class="detail" v-if="detail">
    <van-nav-bar
      title="课程详情"
      fixed
      z-index="999"
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>
    <van-swipe
      class="goods-swipe"
      v-if="detail && detail.images"
      indicator-color="#fff"
      :autoplay="3000"
    >
      <van-swipe-item v-for="thumb in detail.images" :key="thumb">
        <img class="img" :src="thumb" />
      </van-swipe-item>
    </van-swipe>

    <div class="name-box" v-if="detail">
      <span class="top-title">{{ detail.typeDesc }}-{{ detail.name }}</span>
      <span class="price-box">
        <span class="old-price" v-if="detail.originalPrice != detail.price"
          >¥{{ detail.originalPrice }}</span
        >
        <span class="top-price">¥{{ detail.price }}</span>
      </span>
    </div>

    <div class="tags-box">
      <div
        class="tags-flex"
        v-for="(item1, i1) in detail.featureList"
        v-bind:key="i1"
      >
        <!-- <span class="iconfont icon-tag-l"></span> -->
        <!-- <img class="img" src="../../../assets/other/tag-l.png" alt="" /> -->
        <span class="tags">{{ item1 }}</span>
        <!-- <span class="iconfont icon-tag-r"></span> -->

        <!-- <img class="img" src="../../../assets/other/tag-r.png" alt="" /> -->
      </div>
    </div>

    <div
      class="class-info-box"
      v-if="
        detail.trainingModel ||
        detail.reservationMethod ||
        detail.trainingTime ||
        detail.characteristic
      "
    >
      <div class="class-info-item line" v-if="detail.trainingModel">
        <div class="item-top-box">
          <!-- <img class="icon" src="../../../assets/other/car.png" /> -->
          <span class="iconfont icon-car"></span>
          <span class="title">培训车型</span>
        </div>
        <div class="detail-text">{{ detail.trainingModel }}</div>
      </div>
      <div class="class-info-item line" v-if="detail.reservationMethod">
        <div class="item-top-box">
          <span class="iconfont icon-time"></span>
          <!-- <img class="icon" src="../../../assets/other/time.png" /> -->
          <span class="title">培训方式</span>
        </div>
        <div class="detail-text">{{ detail.reservationMethod }}</div>
      </div>
      <div class="class-info-item line" v-if="detail.trainingTime">
        <div class="item-top-box">
          <span class="iconfont icon-mode"></span>
          <!-- <img class="icon" src="../../../assets/other/mode.png" /> -->
          <span class="title">培训时间</span>
        </div>
        <div class="detail-text">{{ detail.trainingTime }}</div>
      </div>
      <div class="class-info-item" v-if="detail.characteristic">
        <div class="item-top-box">
          <!-- <img class="icon" src="../../../assets/other/class.png" /> -->
          <span class="iconfont icon-class"></span>
          <span class="title">班型特征</span>
        </div>
        <div class="detail-text">{{ detail.characteristic }}</div>
      </div>
    </div>

    <div class="text">{{ detail.summary }}</div>

    <div class="imgs-box">
      <img
        class="imgs"
        v-for="(item, i) in detail.detailImages"
        v-bind:key="i"
        :src="item"
      />
    </div>

    <div class="bottom-bar">
      <span class="other-btn" @click="onClickLeft">选择其他班型</span>
      <span class="apply-btn" @click="toApply">立即报名</span>
    </div>
  </div>
</template>



<script>
import { Icon, NavBar, Swipe, SwipeItem } from "vant";

import { ClassDetail_Api } from "@/api/class";
import { getLocalStorage } from "@/core/utils/local-storage";
import { parse } from "qs";

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },

  props: {
    itemId: [String, Number],
  },

  data() {
    return {
      detail: {},
    };
  },

  updated() {},
  mounted() {
    let query = parse(getLocalStorage("query"));
    this.$reqGet(ClassDetail_Api + "/" + this.itemId, {
      ...query,
    }).then((res) => {
      this.detail = res.map.object;
    });
  },

  methods: {
    toApply() {
      var { memberInfo } = getLocalStorage("memberInfo");
      if (!memberInfo) {
        this.$common.classId = this.itemId;
      }
      this.$router.push({
        name: "submitOrder",
        query: { itemId: this.itemId },
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.bottom-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 95px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(127, 127, 127, 0.1);
  justify-content: space-between;

  .other-btn {
    width: 138px;
    height: 44px;
    border-radius: 7px;
    border: 1px solid var(--theme-color);
    font-size: 16px;
    color: var(--theme-color);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-top: 16px;
  }
  .apply-btn {
    margin-left: 6px;
    width: 199px;
    height: 44px;
    background: var(--theme-color);
    border-radius: 7px;
    font-size: 16px;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    margin-top: 16px;
  }
}

.detail {
  width: 100%;
  .class-info-box {
    margin: 0 auto;
    width: 343px;
    background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
    border-radius: 8px;
    border: 1px solid #999999;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .class-info-item {
      width: 311px;
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      .item-top-box {
        display: flex;
        align-items: center;
        .icon {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
        .iconfont {
          font-size: 15px;
        }
        .title {
          margin-left: 8px;
          font-size: 16px;
          color: var(--theme-color);
          font-weight: 500;
        }
      }
      .detail-text {
        color: #333333;
        font-size: 14px;
        width: 311px;
        margin-top: 8px;
        line-height: 21px;
        white-space: pre-line;
      }
    }
    .class-info-item.line {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .text {
    width: 343px;
    margin: 12px auto 12px auto;
    font-size: 14px;
    color: #333;
    white-space: pre-line;
  }
  .goods-swipe {
    width: 343px;
    height: 343px;
    margin: 16px auto 0 auto;
    border-radius: 8px;
    .img {
      object-fit: cover;
      width: 343px;
      height: 343px;
    }
  }
  .imgs-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 16px solid #f1f1f1;
    margin-bottom: 95px;
  }
  .imgs {
    width: 343px;
    margin: 0 auto;
    // object-fit: scale-down;
  }
  .name-box {
    width: 343px;
    display: flex;
    justify-content: space-between;
    margin: 16px auto;
  }
  .price-box {
    display: flex;
    align-items: center;
  }
  .top-title {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
  }
  .old-price {
    color: #999999;
    font-size: 14px;
    text-decoration: line-through;
    margin-right: 4px;
  }
  .top-price {
    color: #ff2525;
    font-weight: 500;
    font-size: 18px;
  }
  .tags-box {
    display: flex;
    align-items: center;
    width: 343px;
    margin: 16px auto;
    flex-wrap: wrap;
  }
  .tags-flex {
    display: flex;
    align-items: center;
    height: 22px;
    margin-right: 4px;
    margin-bottom: 8px;
    .tags {
      padding: 0 1px;
      height: 22px;
      background-color: var(--theme-color);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 12px;
      padding: 0 8px;
      border-radius: 5px;
    }
    .img {
      width: 10px;
      height: 22px;
    }
  }
}
</style>
